<template>
  <div class="index-container">
    <div class="banner">
      <div class="main w">
        <div class="banner-tit">{{ beian.name }}</div>
        <div class="banner-sub">
          打造本地生活资源矩阵，高效推动平台数字化营销
        </div>
      </div>
    </div>
    <div class="nav w">
      <ul class="nav-ul">
        <li class="nav-item">
          <div class="item-div">
            <img src="../assets/nav-icon1.png" alt="全场及权益" />
            <span class="nav-item-title">全场景权益资源聚合</span>
          </div>
          <p class="nav-item-content">
            覆盖吃、喝、玩、乐、购五大消费场景，通过数字化营销提升用户体验
          </p>
        </li>
        <li class="nav-item">
          <div class="item-div">
            <img src="../assets/nav-icon2.png" alt="全场及权益" />
            <span class="nav-item-title">专业技术支撑落地</span>
          </div>
          <p class="nav-item-content">
            支持多种对接模式，帮助客户完成技术搭建，助力商城快速上线
          </p>
        </li>
        <li class="nav-item">
          <div class="item-div">
            <img src="../assets/nav-icon3.png" alt="全场及权益" />
            <span class="nav-item-title">商务全面拓展资源</span>
          </div>
          <p class="nav-item-content">
            强大的商务团队，持续拓展布局市场资源，吸引更多优质品牌入驻平台
          </p>
        </li>
        <li class="nav-item">
          <div class="item-div">
            <img src="../assets/nav-icon4.png" alt="全场及权益" />
            <span class="nav-item-title">客服高效运营保障</span>
          </div>
          <p class="nav-item-content">
            定期策划主题活动，助力爆款产品引流，客服实时答疑，有效实现用户变现
          </p>
        </li>
      </ul>
    </div>
    <div class="profit">
      <h2 class="common-title">权益产品体系</h2>
      <p class="common-describe">
        1000+权益资源、优质生活服务应用、多款营销活动联合发力
      </p>
      <ul class="profit-ul w">
        <li class="profit-item">
          <img src="../assets/profit-icon1.png" alt="权益" />
          <div class="profit-div">
            <p class="item-number">1000+</p>
            <p class="item-font">大牌权益资源</p>
          </div>
        </li>
        <li class="profit-item">
          <img src="../assets/profit-icon2.png" alt="生活" />
          <div class="profit-div">
            <p class="item-number">20+</p>
            <p class="item-font">生活服务应用</p>
          </div>
        </li>
        <li class="profit-item">
          <img src="../assets/profit-icon3.png" alt="活动" />
          <div class="profit-div">
            <p class="item-number">100+</p>
            <p class="item-font">营销活动模板</p>
          </div>
        </li>
        <li class="profit-item">
          <img src="../assets/profit-icon4.png" alt="服务" />
          <div class="profit-div">
            <p class="item-number">100%</p>
            <p class="item-font">专业服务团队</p>
          </div>
        </li>
      </ul>
    </div>
    <swiper
      class="w"
      :options="swiperOption"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide>
        <img style="height: auto" src="../assets/quanyi.png" alt="" />
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <div class="number">
      <h2 class="common-title">全域数字场景构建</h2>
      <p class="common-describe">
        H5、API两大对接方式，适用于社群、公众号、APP等不同形态的流量主
      </p>
      <div class="number-type w">
        <div class="type-item number-h5">
          <p class="type-title">H5</p>
          <p class="type-content">福利商城对接，快速实现获客、裂变、复购</p>
        </div>
        <div class="type-item number-api">
          <p class="type-title">API</p>
          <p class="type-content">API对接模式，智能对接，助力产品轻松售卖</p>
        </div>
      </div>
      <div class="number-arrow"></div>
      <div class="number-kind w">
        <div class="kind-item">
          <div class="kind-div">
            <img src="../assets/number-icon1.png" alt="APP" />
            <span class="kind-title">APP</span>
          </div>
          <p class="kind-content">
            APP嵌入式商城，实现个性化配置，丰富用户场景
          </p>
        </div>
        <div class="kind-item">
          <div class="kind-div">
            <img src="../assets/number-icon2.png" alt="微信生态" />
            <span class="kind-title">微信生态</span>
          </div>
          <p class="kind-content">
            整合微信生态，打造支持公众号、小程序的聚合商城
          </p>
        </div>
        <div class="kind-item">
          <div class="kind-div">
            <img src="../assets/number-icon3.png" alt="私域流量" />
            <span class="kind-title">私域流量</span>
          </div>
          <p class="kind-content">支持全平台渠道获客引流，搭建专属私域流量池</p>
        </div>
      </div>
    </div>

    <!--全链路服务-->
    <div class="link">
      <h2 class="common-title">全链路服务体系</h2>
      <p class="common-describe">
        商务、运营、客服循环发力，智能驱动每一个服务环节
      </p>
      <div class="link-ul w">
        <div class="top">
          <img src="../assets/link-arrow-left.png" alt="" />
          <div class="link-top link-item">
            <img class="link-icon" src="../assets/link-icon1.png" />
            <p class="link-title">商务团队</p>
            <p class="link-content">
              积极拓展市场，寻找多项业务模式，引入更多权益资源
            </p>
          </div>
          <img src="../assets/link-arrow-right.png" alt="" />
        </div>
        <div class="bottom">
          <div class="link-left link-item">
            <img class="link-icon" src="../assets/link-icon2.png" />
            <p class="link-title">运营团队</p>
            <p class="link-content">
              定期策划主题活动，创建爆款营销产品，助力引流变现
            </p>
          </div>
          <img src="../assets/link-arrow-bottom.png" alt="" />
          <div class="link-right link-item">
            <img class="link-icon" src="../assets/link-icon3.png" />
            <p class="link-title">客服团队</p>
            <p class="link-content">
              7*24小时在线服务，解决售后问题，收集需求，持续反馈
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--客户案例-->
    <div class="case">
      <h2 class="common-title">客户案例</h2>
      <div class="case-body w">
        <ul class="case-left">
          <li
            class="case-choice"
            :class="isActive == index ? 'case-active' : 'case-base'"
            v-for="(item, index) in tab"
            :key="index"
            @mouseover="mouserOver(index)"
          >
            <span class="case-title">{{ item }}</span>
            <img
              v-show="isActive == index"
              class="case-arrow"
              src="../assets/case-arrow-hover.png"
            />
            <img
              v-show="isActive != index"
              class="case-arrow"
              src="../assets/case-arrow-base.png"
            />
          </li>
        </ul>
        <div class="case-right">
          <div v-show="isActive == 0" class="case-body-right case-1">
            <img class="case-img" src="../assets/case-icon1.jpg" />
            <div class="case-content">
              <p class="case-content-title">纷纷团权益商城小程序</p>
              <p class="case-content-blue"></p>
              <p class="case-content-body">
                打造专属微信小程序商城，覆盖吃、喝、玩、乐、购，五大消费场景，提升微信公众号粉丝的积极性和活跃度，有效实现平台流量的高转化、高变现。通过丰富的营销活动模式和海量福利权益资源，显著提升了平台用户的粘性，进一步聚拢新老粉丝，促进用户高效转化，平台的变现能力大大提升，打造私域营销闭环。
              </p>
              <img class="case-content-img" src="../assets/case-spot.png" />
            </div>
          </div>
          <div
            v-show="isActive == 1"
            class="case-body-right case-2 case-right-hidden"
          >
            <img class="case-img" src="../assets/case-icon2.jpg" />
            <div class="case-content">
              <p class="case-content-title">本地生活福利公众号</p>
              <p class="case-content-blue"></p>
              <p class="case-content-body">
                丰富的权益资源，打造与APP用户属性相符的高品质福利商城，为用户随时随地提供购物、餐饮、休闲娱乐及生活服务等领域的消费优惠，大大提升APP用户活跃度，实现降本增效。
              </p>
              <img class="case-content-img" src="../assets/case-spot.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import checkHost from "../../public/host";
export default {
  name: "index",
  components: {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        // 设置分页器
        // pagination: {
        //   el: ".swiper-pagination",
        //   // 设置点击可切换
        //   clickable: true,
        // },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 2000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: false,
      },
      swiperList: ["/src/assets/quanyi.png"],
      tab: ["纷纷团权益商城小程序", "本地生活权益APP"],
      isActive: 0,
      beian: {},
    };
  },
  created() {
    // const beian = checkHost(window.location.host);
    const beian = localStorage.getItem("beian");
    this.beian = JSON.parse(beian);
  },
  mounted() {},
  methods: {
    mouserOver(i) {
      this.isActive = i;
    },
    onSwiper() {},
    onSlideChange() {},
  },
};
</script>

<style lang="less" scoped>
.index-container {
  .banner {
    height: 600px;
    // background: url(../assets/firstbanner.jpg) no-repeat center;
    background: url(../assets/content-bg1.png) no-repeat center;
    background-size: auto 100%;

    .main {
      padding-top: 200px;
      height: 600px;

      .banner-tit {
        font-size: 40px;
        // line-height: 22px;
        color: #333333;
      }
      .banner-sub {
        font-size: 18px;
        // line-height: 22px;
        color: #555555;
        margin-top: 19px;
      }
    }
  }

  .nav {
    .nav-ul {
      display: flex;
      justify-content: space-between;

      .nav-item {
        margin-top: -50px;
        width: 320px;
        height: 162px;
        border-radius: 2px;
        background: url(../assets/nav-bg.png) no-repeat center;
        transition: all 0.5s;
        .item-div {
          margin: 23px 0 12px 21px;
          display: flex;
          align-items: center;
          img {
            width: 32px;
            height: 32px;
            // vertical-align: top;
            margin-right: 6px;
          }
          .nav-item-title {
            font-size: 19px;
            color: #333333;
          }
        }
        .nav-item-content {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0px;
          color: #717a89;
          margin: 0 21px 0 26px;
        }

        &:hover {
          margin-top: -100px;
        }
      }
    }
  }

  .profit {
    margin-top: 120px;
    height: 563px;
    background-color: #f5f7fa;

    .common-title {
      padding-top: 98px;
      color: #000;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
    }

    .common-describe {
      margin-top: 25px;
      font-size: 18px;
      color: #3d485d;
      text-align: center;
    }

    .profit-ul {
      margin: 83px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .profit-item {
        display: flex;

        img {
          margin-right: 18px;
          width: 78px;
          height: 81px;
        }

        .item-number {
          color: #303540;
          font-size: 36px;
        }
        .item-font {
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 29px;
          text-align: center;
          font-size: 16px;
          color: #303540;
          border-radius: 2px;
          border: solid 1px #798496;
        }
      }
    }
  }

  .swiper-container {
    margin: -140px auto 100px;
    height: 300px;
    overflow: hidden;
    background-color: #000;
    box-shadow: 0 2px 10px 0 rgba(51, 51, 51, 0.1);
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
  }

  .number {
    height: 675px;

    .common-title {
      font-size: 32px;
      font-weight: 500;
      text-align: center;
    }

    .common-describe {
      font-size: 18px;
      color: #3d485d;
      text-align: center;
      margin-top: 25px;
    }

    .number-type {
      margin-top: 69px;
      display: flex;
      align-items: center;
      height: 174px;

      .type-item {
        flex: 1;
        height: 100%;

        .type-title {
          margin: 58px 0 16px 39px;
          line-height: 22px;
          color: #fff;
          font-size: 28px;
        }

        .type-content {
          margin-left: 39px;
          color: #fff;
          font-size: 18px;
        }
      }

      .number-h5 {
        background: url(../assets/number-h5.png) no-repeat;
      }
      .number-api {
        background: url(../assets/number-api.png) no-repeat;
      }
    }

    .number-arrow {
      margin: 0 auto 12px;
      width: 196px;
      height: 97px;
      background: url(../assets/number-arrow.png) no-repeat center;
    }

    .number-kind {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .kind-item {
        padding: 0 20px 0 30px;
        width: 380px;
        height: 203px;
        background: url(../assets/number-kind-bg.png) no-repeat center;

        .kind-div {
          margin: 44px 0 18px;
          display: flex;
          align-items: center;
          img {
            margin-right: 12px;
            width: 26px;
            height: 26px;
          }

          .kind-title {
            font-size: 22px;
            color: #333333;
          }
        }

        .kind-content {
          font-size: 16px;
          color: #333333;
        }
      }
    }
  }

  .link {
    height: 807px;
    // background: url(../assets/link-bg.jpg) no-repeat center;
    background: url(../assets/big-banner.png) no-repeat center;
    background-size: auto 100%;

    .common-title {
      padding-top: 96px;
      font-size: 32px;
      color: #fff;
      text-align: center;
    }

    .common-describe {
      margin: 25px 0 74px;
      font-size: 18px;
      color: #fff;
      text-align: center;
    }

    .link-ul {
      .top {
        display: flex;
        justify-content: center;

        img {
          margin-top: 90px;
          width: 219px;
          height: 182px;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 408px;
          height: 24px;
        }
      }

      .link-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 305px;
        height: 207px;
        background: url(../assets/link-arrow-bg.png) no-repeat center;
        .link-icon {
          margin: 34px 0 5px;
          width: 52px;
          height: 52px;
        }
        .link-title {
          margin-bottom: 10px;
          font-size: 20px;
          color: #fff;
        }
        .link-content {
          margin: 0 30px;
          text-align: justify;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }

  .case {
    margin-bottom: 100px;
    .common-title {
      margin: 90px 0;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
    }

    .case-body {
      display: flex;
      justify-content: space-between;

      .case-left {
        border-right: 1px solid #e7e7e7;

        .case-choice {
          padding: 0 10px 0 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 334px;
          height: 113px;
          color: #fff;

          .case-title {
            font-size: 20px;
          }
        }

        .case-active {
          box-shadow: 0px 5px 18px 0px rgba(36, 102, 238, 0.39);
          background-image: linear-gradient(15deg, #1c5ee5 0%, #3779ff 100%);

          .case-arrow {
            width: 70px;
            height: 70px;
          }
        }

        .case-base {
          color: #333;

          .case-arrow {
            margin-right: 25px;
            width: 19px;
            height: 16px;
          }
        }
      }

      .case-right {
        margin-left: 100px;

        .case-body-right {
          display: flex;

          .case-img {
            margin-right: 68px;
            width: 324px;
            height: 625px;
          }

          .case-content {
            position: relative;

            .case-content-title {
              margin-top: 46px;
              font-size: 22px;
              font-weight: bold;
              color: #333;
              line-height: 22px;
            }

            .case-content-blue {
              width: 58px;
              height: 3px;
              background-color: #275aff;
              margin: 14px 0 34px;
            }

            .case-content-body {
              font-size: 16px;
              line-height: 30px;
              color: #333333;
            }

            .case-content-img {
              position: absolute;
              bottom: 20px;
              right: 0;
              width: 261px;
              height: 150px;
            }
          }
        }
      }
    }
  }
}
</style>
